import React from "react"
import Layout from "../components/layout"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope, faPhone, faHome } from "@fortawesome/free-solid-svg-icons"

const Contact = props => (
  <Layout>
    <div className="container contacts">
      <div className="row">
        <div className="col-lg-12 text-center">
          <h1 className="contactTitle font-bold">Write us</h1>
          <p className="subTitle font-bold">We would love to do business with you!</p>
        </div>
        <div >
          <form
            className="mx-auto" 
            name="contact"
            method="post"
            action="/success"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="bot-field" />

            <div className="block font-bold mb-2 text-xs uppercase">
              <label htmlFor="name">Name</label>
              <input className= "appearance-none block bg-gray-200 mb-6 px-3 py-2 rounded-md text-gray-700 w-full" type="text" name="name" id="name" required />
            </div>
            <div className="block font-bold mb-2 text-xs uppercase">
              <label htmlFor="email">Email</label>
              <input className= "appearance-none block bg-gray-200 mb-6 px-3 py-2 rounded-md text-gray-700 w-full"type="text" name="email" id="email" required />
            </div>
            <div className="appearance-none block bg-gray-200 mb-6 px-3 py-2 rounded-md text-gray-700 w-full">
              <label htmlFor="message">Message</label>
            <textarea
            className="appearance-none bg-gray-200 mb-6 px-3 py-2 rounded-md text-gray-700 w-full"
            id="message"
            placeholder="Say something..."
            rows="8"
            required
            />
            </div>
            <ul className="border-b-4 border-gray-800 hover:border-gray-700 font-bold px-4 py-2 rounded text-sm text-purple">
              <li>
                <input type="submit" value="Send Message" className="border-b-4 border-gray-800 hover:border-gray-700 bg-purple-700 hover:bg-gray-600 font-bold px-4 py-2 rounded text-sm text-white" />
              </li>
              
            </ul>
          </form>
        </div>
        <div>
          <section className="conatctSplit">
            <section>
              <div className="contact-method">
                <span className="icon">
                  <FontAwesomeIcon icon={faEnvelope} />
                </span>
                <h3 className="bg-purple-700 text-white font-bold inline-block my-8 p-3">Email</h3>
                <a href="#" className="font-bold">info@shilohhardware.co.zw</a>
              </div>
            
            
              <div className="contact-method">
                <span className="icon">
                  <FontAwesomeIcon icon={faPhone} />
                </span>
                <h3 className="bg-purple-700 text-white font-bold inline-block my-8 p-3">Phone</h3>
                <span className="font-bold">(263) 774 500 868</span>

              </div>
              <div className="contact-method">
                <span className="icon">
                  <FontAwesomeIcon icon={faPhone} />
                </span>
                <h3 className="bg-purple-700 text-white font-bold inline-block my-8 p-3">Phone</h3>
                <span className="font-bold">(263) 773 386 695</span>
              </div>
            </section>
            <section>
              <div className="contact-method">
                <span className="icon">
                  <FontAwesomeIcon icon={faHome} />
                </span>
                <h2 className="bg-purple-700 text-white font-bold inline-block my-8 p-3">Physical Address</h2>
                <span>
                <h3 className="font-bold">3 Locations with more to come..</h3>
                  1.)Sandton, 
                  2.)Corner Harare Drive and 2nd Street, 
                  3.)Haydon Park
                  <br />
                  Harare
                  <br />
                  Zimbabwe
                </span>
              </div>
            </section>
          </section>
        </div>
      </div>
    </div>
  </Layout>
)

export default Contact